/**
 * @generated SignedSource<<0e9e58f1d0335ac1ad2cf39873dfb4d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DisplayPopupAgainStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type PopupActionKind = "DURATION" | "SCROLL_RATE" | "SCROLL_PIXEL" | "EXIT_PAGE" | "EXIT_AND_SCROLL_RATE_1" | "EXIT_AND_SCROLL_RATE_2" | "EXIT_AND_SCROLL_RATE_3" | "SCROLL_RATE_RANGE" | "CUSTOM_EVENT" | "%future added value";
export type PopupAnimationKind = "NO_ANIMATION" | "FROM_RIGHT" | "FROM_LEFT" | "FROM_TOP" | "FROM_BOTTOM" | "%future added value";
export type PopupCloseButton = "CLOSE_ICON_DEFAULT" | "CLOSE_ICON_JP" | "CLOSE_ICON_EN" | "%future added value";
export type PopupCloseButtonPosition = "TOP_RIGHT" | "TOP_LEFT" | "BOTTOM_LEFT" | "BOTTOM_RIGHT" | "%future added value";
export type PopupClosePopupByClickingOutside = "DISABLED" | "ENABLED" | "%future added value";
export type PopupColorKind = "DEFAULT" | "WHITE" | "GRAY" | "BLACK" | "RED" | "BLUE" | "GREEN" | "YELLOW" | "%future added value";
export type PopupContentKind = "IMAGE" | "VIDEO" | "TEXT" | "HTML" | "%future added value";
export type PopupDisplayKind = "CENTER_MODAL" | "LEFT_MODAL" | "RIGHT_MODAL" | "TOP_MODAL" | "BOTTOM_MODAL" | "LEFT_BOTTOM_MODAL" | "RIGHT_BOTTOM_MODAL" | "CUSTOM_MODAL" | "%future added value";
export type PopupHrefTarget = "BLANK" | "TOP" | "%future added value";
export type PopupKind = "A_PATTERN" | "B_PATTERN" | "%future added value";
export type PopupYdaTrigger = "YDA_AFFI" | "YDA_ALWAYS" | "YDA_SHOW" | "%future added value";
export type UserRole = "SUPER" | "ADMIN_ALL" | "ADMIN" | "JUDGE" | "CLIENT" | "SAAS_CLIENT" | "%future added value";
export type PopupGroupPatternDetailPageContainer_Query$variables = {
  popupSlug: string;
  popupGroupSlug: string;
};
export type PopupGroupPatternDetailPageContainer_QueryVariables = PopupGroupPatternDetailPageContainer_Query$variables;
export type PopupGroupPatternDetailPageContainer_Query$data = {
  readonly viewer: {
    readonly role: UserRole;
  };
  readonly popupGroup: {
    readonly name: string;
    readonly page: {
      readonly kind: PageKind;
      readonly url: string;
      readonly sortNumber: number;
      readonly name: string;
      readonly funnel: {
        readonly name: string;
        readonly siteUrl: string;
      };
    };
  } | null;
  readonly popup: {
    readonly id: string;
    readonly actionKind: PopupActionKind | null;
    readonly backgroundOpacity: number;
    readonly button: string | null;
    readonly buttonGradationColor: string | null;
    readonly buttonColor: string | null;
    readonly buttonTextColor: string | null;
    readonly contentKind: PopupContentKind | null;
    readonly customEvent: string | null;
    readonly closePopupByClickingOutside: PopupClosePopupByClickingOutside;
    readonly hideByScrollPercentage: number;
    readonly hideByOnfocus: boolean;
    readonly quitTiming: number | null;
    readonly displayCountLimit: number;
    readonly displayPopupAgain: DisplayPopupAgainStatus;
    readonly displayPopupAgainMessage: string | null;
    readonly displayPopupAgainImageUrl: string | null;
    readonly closeButtonPosition: PopupCloseButtonPosition;
    readonly closeButtonType: PopupCloseButton;
    readonly colorKind: PopupColorKind | null;
    readonly contentWidth: number | null;
    readonly displayKind: PopupDisplayKind | null;
    readonly description: string | null;
    readonly displayOnTabFocus: boolean;
    readonly displayOnMouseOut: boolean;
    readonly enableTapWindow: boolean;
    readonly htmlContent: string | null;
    readonly hrefTarget: PopupHrefTarget;
    readonly honeycombCode: string | null;
    readonly isBeforeunload: boolean;
    readonly isClose: boolean;
    readonly isInheritQuery: boolean;
    readonly isDisplayWhenNoOperation: boolean;
    readonly name: string | null;
    readonly page: {
      readonly id: string;
    };
    readonly scrollRateRange: string;
    readonly slug: string;
    readonly status: DistributionStatus;
    readonly kind: PopupKind | null;
    readonly timing: number | null;
    readonly titleTextColor: string | null;
    readonly title: string | null;
    readonly url: string | null;
    readonly underlayerColor: string | null;
    readonly windowColor: string | null;
    readonly weight: number | null;
    readonly xOffset: number | null;
    readonly ydaTrigger: PopupYdaTrigger | null;
    readonly yOffset: number | null;
    readonly popupVideo: {
      readonly video: string | null;
    } | null;
    readonly animationKind: PopupAnimationKind;
    readonly popupImages: ReadonlyArray<{
      readonly alt: string | null;
      readonly linkUrl: string | null;
      readonly imageUrl: string | null;
      readonly isInheritQuery: boolean | null;
      readonly honeycombCode: string | null;
    }>;
  } | null;
};
export type PopupGroupPatternDetailPageContainer_QueryResponse = PopupGroupPatternDetailPageContainer_Query$data;
export type PopupGroupPatternDetailPageContainer_Query = {
  variables: PopupGroupPatternDetailPageContainer_QueryVariables;
  response: PopupGroupPatternDetailPageContainer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "popupGroupSlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "popupSlug"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "popupGroupSlug"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sortNumber",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "siteUrl",
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "popupSlug"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actionKind",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "backgroundOpacity",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "button",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "buttonGradationColor",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "buttonColor",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "buttonTextColor",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentKind",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customEvent",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "closePopupByClickingOutside",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hideByScrollPercentage",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hideByOnfocus",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quitTiming",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayCountLimit",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayPopupAgain",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayPopupAgainMessage",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayPopupAgainImageUrl",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "closeButtonPosition",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "closeButtonType",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "colorKind",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentWidth",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayKind",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayOnTabFocus",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayOnMouseOut",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enableTapWindow",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "htmlContent",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hrefTarget",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "honeycombCode",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isBeforeunload",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isClose",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInheritQuery",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isDisplayWhenNoOperation",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "concreteType": "Page",
  "kind": "LinkedField",
  "name": "page",
  "plural": false,
  "selections": [
    (v10/*: any*/)
  ],
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "scrollRateRange",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timing",
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "titleTextColor",
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v50 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "underlayerColor",
  "storageKey": null
},
v51 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "windowColor",
  "storageKey": null
},
v52 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weight",
  "storageKey": null
},
v53 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "xOffset",
  "storageKey": null
},
v54 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ydaTrigger",
  "storageKey": null
},
v55 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "yOffset",
  "storageKey": null
},
v56 = {
  "alias": null,
  "args": null,
  "concreteType": "PopupVideo",
  "kind": "LinkedField",
  "name": "popupVideo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "video",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v57 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "animationKind",
  "storageKey": null
},
v58 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "alt",
  "storageKey": null
},
v59 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkUrl",
  "storageKey": null
},
v60 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupGroupPatternDetailPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "PopupGroup",
        "kind": "LinkedField",
        "name": "popupGroup",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "page",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Funnel",
                "kind": "LinkedField",
                "name": "funnel",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "Popup",
        "kind": "LinkedField",
        "name": "popup",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          (v41/*: any*/),
          (v42/*: any*/),
          (v4/*: any*/),
          (v43/*: any*/),
          (v44/*: any*/),
          (v45/*: any*/),
          (v46/*: any*/),
          (v5/*: any*/),
          (v47/*: any*/),
          (v48/*: any*/),
          (v49/*: any*/),
          (v6/*: any*/),
          (v50/*: any*/),
          (v51/*: any*/),
          (v52/*: any*/),
          (v53/*: any*/),
          (v54/*: any*/),
          (v55/*: any*/),
          (v56/*: any*/),
          (v57/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PopupImage",
            "kind": "LinkedField",
            "name": "popupImages",
            "plural": true,
            "selections": [
              (v58/*: any*/),
              (v59/*: any*/),
              (v60/*: any*/),
              (v41/*: any*/),
              (v38/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PopupGroupPatternDetailPageContainer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "PopupGroup",
        "kind": "LinkedField",
        "name": "popupGroup",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "page",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Funnel",
                "kind": "LinkedField",
                "name": "funnel",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v8/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "Popup",
        "kind": "LinkedField",
        "name": "popup",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          (v41/*: any*/),
          (v42/*: any*/),
          (v4/*: any*/),
          (v43/*: any*/),
          (v44/*: any*/),
          (v45/*: any*/),
          (v46/*: any*/),
          (v5/*: any*/),
          (v47/*: any*/),
          (v48/*: any*/),
          (v49/*: any*/),
          (v6/*: any*/),
          (v50/*: any*/),
          (v51/*: any*/),
          (v52/*: any*/),
          (v53/*: any*/),
          (v54/*: any*/),
          (v55/*: any*/),
          (v56/*: any*/),
          (v57/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PopupImage",
            "kind": "LinkedField",
            "name": "popupImages",
            "plural": true,
            "selections": [
              (v58/*: any*/),
              (v59/*: any*/),
              (v60/*: any*/),
              (v41/*: any*/),
              (v38/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "37a8c3f14e82ec2c5751eaf9e65c3666",
    "id": null,
    "metadata": {},
    "name": "PopupGroupPatternDetailPageContainer_Query",
    "operationKind": "query",
    "text": "query PopupGroupPatternDetailPageContainer_Query(\n  $popupSlug: String!\n  $popupGroupSlug: String!\n) {\n  viewer {\n    role\n    id\n  }\n  popupGroup(slug: $popupGroupSlug) {\n    name\n    page {\n      kind\n      url\n      sortNumber\n      name\n      funnel {\n        name\n        siteUrl\n        id\n      }\n      id\n    }\n    id\n  }\n  popup(slug: $popupSlug) {\n    id\n    actionKind\n    backgroundOpacity\n    button\n    buttonGradationColor\n    buttonColor\n    buttonTextColor\n    contentKind\n    customEvent\n    closePopupByClickingOutside\n    hideByScrollPercentage\n    hideByOnfocus\n    quitTiming\n    displayCountLimit\n    displayPopupAgain\n    displayPopupAgainMessage\n    displayPopupAgainImageUrl\n    closeButtonPosition\n    closeButtonType\n    colorKind\n    contentWidth\n    displayKind\n    description\n    displayOnTabFocus\n    displayOnMouseOut\n    enableTapWindow\n    htmlContent\n    hrefTarget\n    honeycombCode\n    isBeforeunload\n    isClose\n    isInheritQuery\n    isDisplayWhenNoOperation\n    name\n    page {\n      id\n    }\n    scrollRateRange\n    slug\n    status\n    kind\n    timing\n    titleTextColor\n    title\n    url\n    underlayerColor\n    windowColor\n    weight\n    xOffset\n    ydaTrigger\n    yOffset\n    popupVideo {\n      video\n    }\n    animationKind\n    popupImages {\n      alt\n      linkUrl\n      imageUrl\n      isInheritQuery\n      honeycombCode\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f7a037c71feaa804597db51777083622";

export default node;
