import { boolean, mixed, number, object, string } from "yup";

import {
  DisplayPopupAgainStatus,
  DistributionStatus,
  InputMaybe,
  PopupActionKind,
  PopupAnimationKind,
  PopupCloseButton,
  PopupCloseButtonPosition,
  PopupClosePopupByClickingOutside,
  PopupColorKind,
  PopupContentKind,
  PopupDisplayKind,
  PopupHrefTarget,
  PopupImageInput,
  PopupVideoInput,
  PopupYdaTrigger,
  Scalars,
} from "~/src/__generated__/schema";

type PopupImageField = PopupImageInput & { imageUrl: string };

export type FormValues = {
  name: string;
  contentKind: PopupContentKind;
  colorKind: PopupColorKind;
  title: string | null;
  description: string | null;
  button: string | null;
  isInheritQuery: boolean;
  url: string | null;
  hrefTarget: PopupHrefTarget;
  contentWidth: number | null;
  actionKind: PopupActionKind;
  displayKind: PopupDisplayKind;
  xOffset: number;
  xOffsetUnit: string;
  yOffset: number;
  yOffsetUnit: string;
  enableTapWindow: boolean;
  closePopupByClickingOutside: PopupClosePopupByClickingOutside;
  closeButtonPosition: PopupCloseButtonPosition;
  closeButtonType: PopupCloseButton;
  backgroundOpacity: number;
  hideByScrollPercentage: number;
  hideByOnfocus: boolean;
  quitTiming: number;
  displayCountLimit: number;
  displayPopupAgain: DisplayPopupAgainStatus;
  displayPopupAgainMessage: string | null;
  displayPopupAgainImage: {
    image?: InputMaybe<Scalars["Upload"]>;
    imageUrl: string | null;
  };
  displayOnTabFocus: boolean;
  displayOnMouseOut: boolean;
  animationKind: PopupAnimationKind;
  status: DistributionStatus;
  isClose: boolean;
  popupImages: PopupImageField[];
  popupVideo: PopupVideoInput | null;
  htmlContent: string | null;
  weight: number;
  originalPatternWeight: number;
  ydaTrigger: PopupYdaTrigger | null;
  honeycombCode: string | null;
  isDisplayWhenNoOperation: boolean;
  timing: number | null;
  scrollRateRange: string;
  customEvent: string;
};

export const validationSchema = object({
  name: string().required().max(50),
  contentKind: string().required(),
  colorKind: string().required(),
  title: string().nullable().max(50),
  description: string().nullable().max(250),
  button: string().nullable().max(18),
  isInheritQuery: boolean().required(),
  url: string().nullable(),
  hrefTarget: string().required(),
  contentWidth: number().nullable().min(200).max(450),
  actionKind: string().required(),
  displayKind: string().required(),
  xOffset: number().nullable(),
  xOffsetUnit: string().nullable(),
  yOffset: number().nullable(),
  yOffsetUnit: string().nullable(),
  enableTapWindow: boolean().required(),
  backgroundOpacity: number().required().min(0.0).max(1),
  hideByScrollPercentage: number().required().min(0).max(100),
  hideByOnfocus: boolean().required(),
  quitTiming: number().required(),
  displayCountLimit: number().required(),
  displayPopupAgain: string().required(),
  displayPopupAgainMessage: string().nullable(),
  displayOnTabFocus: boolean().required(),
  displayOnMouseOut: boolean().required(),
  status: string().required(),
  isClose: boolean().required(),
  closeButtonPosition: string().required(),
  htmlContent: string().nullable(),
  weight: number().required(),
  originalPatternWeight: number().nullable(),
  ydaTrigger: mixed<PopupYdaTrigger>().nullable(),
  honeycombCode: string().url().nullable(),
  isDisplayWhenNoOperation: boolean().required(),
  animationKind: string().required(),
  closePopupByClickingOutside: string().required(),
  timing: number().nullable(),
  scrollRateRange: string().required(),
});
