import { useToast } from "@chakra-ui/react";
import { includes } from "ramda";
import { FC, useCallback } from "react";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";

import { DeliverKind } from "~/src/__generated__/schema";
import { PopupBulkEditFormContainer_Mutation } from "~/src/__relay_artifacts__/PopupBulkEditFormContainer_Mutation.graphql";
import { PopupBulkEditFormContainer_popup$key } from "~/src/__relay_artifacts__/PopupBulkEditFormContainer_popup.graphql";
import { PopupBulkEditFormContainer_Query } from "~/src/__relay_artifacts__/PopupBulkEditFormContainer_Query.graphql";
import {
  PopupGroupPatternForm,
  PopupGroupPatternFormProps,
  PopupGroupPatternFormValues,
} from "~/src/components/features/popup";
import { useFormErrorHandler } from "~/src/lib/hooks";
import { useMutationCommit } from "~/src/lib/react-relay";
import { delayChunkPromise } from "~/src/lib/utils";

type Popup = {
  id: string;
  popupImages: { id: string; index: number }[];
  isEditTarget: boolean;
};

export type Props = {
  deliverKind: DeliverKind;
  onClose: () => void;
  targetPopups: Popup[];
  popupRef: PopupBulkEditFormContainer_popup$key;
};

const query = graphql`
  query PopupBulkEditFormContainer_Query {
    viewer {
      role
    }
  }
`;

const fragment = graphql`
  fragment PopupBulkEditFormContainer_popup on Popup {
    id
    slug
    page {
      id
      funnel {
        id
      }
    }
    popupImages {
      id
      alt
      linkUrl
      imageUrl
      isInheritQuery
      honeycombCode
    }
    popupVideo {
      linkUrl
      video
      screenType
    }
    name
    contentKind
    colorKind
    title
    description
    button
    isInheritQuery
    url
    hrefTarget
    contentWidth
    actionKind
    displayKind
    xOffset
    xOffsetUnit
    yOffset
    yOffsetUnit
    enableTapWindow
    backgroundOpacity
    quitTiming
    displayCountLimit
    status
    displayPopupAgain
    displayPopupAgainImageUrl
    displayPopupAgainMessage
    displayOnMouseOut
    displayOnTabFocus
    isClose
    htmlContent
    weight
    animationKind
    closePopupByClickingOutside
    closeButtonPosition
    closeButtonType
    hideByScrollPercentage
    hideByOnfocus
    ydaTrigger
    honeycombCode
    isDisplayWhenNoOperation
    popupGroup {
      id
      deliverKind
      originalPopup {
        weight
      }
    }
    scrollRateRange
    customEvent
  }
`;

const mutation = graphql`
  mutation PopupBulkEditFormContainer_Mutation($input: UpdatePopupInput!) {
    updatePopup(input: $input) {
      popup {
        slug
        ...PopupBulkEditFormContainer_popup
      }
    }
  }
`;

export const PopupBulkEditFormContainer: FC<Props> = ({
  popupRef,
  deliverKind,
  targetPopups,
  onClose,
}) => {
  const toast = useToast();

  const { viewer } = useLazyLoadQuery<PopupBulkEditFormContainer_Query>(
    query,
    {}
  );

  const popup = useFragment(fragment, popupRef);

  const mutate =
    useMutationCommit<PopupBulkEditFormContainer_Mutation>(mutation);

  const { onFormError } = useFormErrorHandler();

  const handleSubmit = useCallback<PopupGroupPatternFormProps["onSubmit"]>(
    async (
      {
        enableTapWindow,
        popupImages,
        popupVideo,
        originalPatternWeight,
        displayPopupAgainImage,
        ...values
      },
      { setErrors }
    ) => {
      try {
        const deletedPopupImageIndex: number[] = [];

        const mutations: Promise<{}>[] = targetPopups.map((popup) => {
          if (popup.isEditTarget) {
            const popupImageIds = popupImages.map((pi) => pi.id);
            const deletedPopupImageIds = popup.popupImages.filter(
              (i) => !includes(i.id, popupImageIds)
            );
            deletedPopupImageIds.forEach((id) =>
              deletedPopupImageIndex.push(id.index)
            );
          }

          const uploadables: Record<string, File> = {};
          if (popupImages) {
            popupImages.forEach(({ image }, index) => {
              if (image)
                uploadables[`variables.input.popupImages.${index}.image`] =
                  image;
            });
          }

          if (displayPopupAgainImage && displayPopupAgainImage.image) {
            uploadables[`variables.input.displayPopupAgainImage`] =
              displayPopupAgainImage.image;
          }

          const filteredPopupImageIds = popup.popupImages.filter(
            (pi) => !includes(pi.index, deletedPopupImageIndex)
          );

          const _popupImages = (popupImages || []).map(
            ({ image, imageUrl, ...rest }, index) => {
              if (!rest.id) return rest;
              if (!popup.isEditTarget) {
                rest.id = filteredPopupImageIds[index].id;
              }
              return rest;
            }
          );
          return mutate({
            variables: {
              input: {
                popupId: popup.id,
                enableTapWindow,
                popupImages: _popupImages,
                popupVideo,
                ...values,
              },
            },
            uploadables,
          });
        });

        await delayChunkPromise(mutations);

        toast({
          title: "ポップアップパターンを一括更新しました",
          status: "success",
        });
        onClose();
      } catch (err) {
        onFormError(err, setErrors);
      }
    },
    [mutate, onFormError, toast, onClose, targetPopups]
  );

  const initialValues: PopupGroupPatternFormValues = {
    name: popup.name || "",
    contentKind: popup.contentKind || "IMAGE",
    colorKind: popup.colorKind || "DEFAULT",
    title: popup.title,
    description: popup.description,
    button: popup.button,
    isInheritQuery: popup.isInheritQuery,
    url: popup.url,
    hrefTarget: popup.hrefTarget,
    contentWidth: popup.contentWidth,
    actionKind: popup.actionKind || "EXIT_PAGE",
    displayKind: popup.displayKind || "CENTER_MODAL",
    xOffset: popup.xOffset || 0,
    xOffsetUnit: popup.xOffsetUnit,
    yOffset: popup.yOffset || 0,
    yOffsetUnit: popup.yOffsetUnit,
    enableTapWindow: popup.enableTapWindow,
    backgroundOpacity: popup.backgroundOpacity,
    // FIXME: DB schemaの更新後にAPIのnull制約も修正できるので、修正する
    // https://macbee-planet-88.slack.com/archives/C03SGKK7QKS/p1673596296969749
    quitTiming: popup.quitTiming || 0,
    displayCountLimit: popup.displayCountLimit,
    isClose: popup.isClose,
    status: popup.status,
    displayPopupAgain: popup.displayPopupAgain,
    displayPopupAgainMessage: popup.displayPopupAgainMessage,
    displayPopupAgainImage: { imageUrl: popup.displayPopupAgainImageUrl },
    displayOnTabFocus: popup.displayOnTabFocus,
    displayOnMouseOut: popup.displayOnMouseOut,
    popupImages: popup.popupImages.map((popupImage) => {
      return {
        alt: popupImage.alt === null ? undefined : popupImage.alt,
        honeycombCode:
          popupImage.honeycombCode === null
            ? undefined
            : popupImage.honeycombCode,
        id: popupImage.id,
        imageUrl: popupImage.imageUrl || "",
        isInheritQuery: popupImage.isInheritQuery,
        linkUrl: popupImage.linkUrl === null ? undefined : popupImage.linkUrl,
      };
    }),
    popupVideo: popup.popupVideo,
    htmlContent: popup.htmlContent,
    weight: popup.weight || 0,
    animationKind: popup.animationKind,
    closePopupByClickingOutside: popup.closePopupByClickingOutside,
    closeButtonPosition: popup.closeButtonPosition,
    hideByScrollPercentage: popup.hideByScrollPercentage,
    hideByOnfocus: popup.hideByOnfocus,
    originalPatternWeight: popup.popupGroup.originalPopup.weight || 0,
    ydaTrigger: popup.ydaTrigger,
    honeycombCode: popup.honeycombCode,
    isDisplayWhenNoOperation: popup.isDisplayWhenNoOperation,
    timing: 0,
    scrollRateRange: popup.scrollRateRange,
    customEvent: popup.customEvent || "",
    closeButtonType: popup.closeButtonType,
  };

  return (
    <PopupGroupPatternForm
      // TODO: do not use any
      initialValues={initialValues}
      enableWeightFields={deliverKind === "WEIGHT"}
      onCancelClick={onClose}
      onSubmit={handleSubmit}
      role={viewer.role}
      isDisableOriginalForm
    />
  );
};
