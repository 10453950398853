/**
 * @generated SignedSource<<5ff366352d80b563b17cc74b8210aee9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DisplayPopupAgainStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type PopupActionKind = "DURATION" | "SCROLL_RATE" | "SCROLL_PIXEL" | "EXIT_PAGE" | "EXIT_AND_SCROLL_RATE_1" | "EXIT_AND_SCROLL_RATE_2" | "EXIT_AND_SCROLL_RATE_3" | "SCROLL_RATE_RANGE" | "CUSTOM_EVENT" | "%future added value";
export type PopupAnimationKind = "NO_ANIMATION" | "FROM_RIGHT" | "FROM_LEFT" | "FROM_TOP" | "FROM_BOTTOM" | "%future added value";
export type PopupCloseButton = "CLOSE_ICON_DEFAULT" | "CLOSE_ICON_JP" | "CLOSE_ICON_EN" | "%future added value";
export type PopupCloseButtonPosition = "TOP_RIGHT" | "TOP_LEFT" | "BOTTOM_LEFT" | "BOTTOM_RIGHT" | "%future added value";
export type PopupClosePopupByClickingOutside = "DISABLED" | "ENABLED" | "%future added value";
export type PopupColorKind = "DEFAULT" | "WHITE" | "GRAY" | "BLACK" | "RED" | "BLUE" | "GREEN" | "YELLOW" | "%future added value";
export type PopupContentKind = "IMAGE" | "VIDEO" | "TEXT" | "HTML" | "%future added value";
export type PopupDisplayKind = "CENTER_MODAL" | "LEFT_MODAL" | "RIGHT_MODAL" | "TOP_MODAL" | "BOTTOM_MODAL" | "LEFT_BOTTOM_MODAL" | "RIGHT_BOTTOM_MODAL" | "CUSTOM_MODAL" | "%future added value";
export type PopupHrefTarget = "BLANK" | "TOP" | "%future added value";
export type PopupVideoScreenKind = "NORMAL" | "FULLSCREEN" | "%future added value";
export type PopupYdaTrigger = "YDA_AFFI" | "YDA_ALWAYS" | "YDA_SHOW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PopupGroupPatternEditScreenContainer_popup$data = {
  readonly id: string;
  readonly slug: string;
  readonly page: {
    readonly id: string;
    readonly funnel: {
      readonly id: string;
    };
  };
  readonly popupImages: ReadonlyArray<{
    readonly id: string;
    readonly alt: string | null;
    readonly linkUrl: string | null;
    readonly imageUrl: string | null;
    readonly isInheritQuery: boolean | null;
    readonly honeycombCode: string | null;
  }>;
  readonly popupVideo: {
    readonly linkUrl: string | null;
    readonly video: string | null;
    readonly screenType: PopupVideoScreenKind;
  } | null;
  readonly name: string | null;
  readonly contentKind: PopupContentKind | null;
  readonly colorKind: PopupColorKind | null;
  readonly title: string | null;
  readonly description: string | null;
  readonly button: string | null;
  readonly isInheritQuery: boolean;
  readonly url: string | null;
  readonly hrefTarget: PopupHrefTarget;
  readonly contentWidth: number | null;
  readonly actionKind: PopupActionKind | null;
  readonly displayKind: PopupDisplayKind | null;
  readonly xOffset: number | null;
  readonly xOffsetUnit: string;
  readonly yOffset: number | null;
  readonly yOffsetUnit: string;
  readonly enableTapWindow: boolean;
  readonly backgroundOpacity: number;
  readonly quitTiming: number | null;
  readonly displayCountLimit: number;
  readonly status: DistributionStatus;
  readonly displayPopupAgain: DisplayPopupAgainStatus;
  readonly displayPopupAgainImageUrl: string | null;
  readonly displayPopupAgainMessage: string | null;
  readonly displayOnMouseOut: boolean;
  readonly displayOnTabFocus: boolean;
  readonly isClose: boolean;
  readonly htmlContent: string | null;
  readonly weight: number | null;
  readonly animationKind: PopupAnimationKind;
  readonly closePopupByClickingOutside: PopupClosePopupByClickingOutside;
  readonly closeButtonPosition: PopupCloseButtonPosition;
  readonly closeButtonType: PopupCloseButton;
  readonly hideByScrollPercentage: number;
  readonly hideByOnfocus: boolean;
  readonly ydaTrigger: PopupYdaTrigger | null;
  readonly honeycombCode: string | null;
  readonly isDisplayWhenNoOperation: boolean;
  readonly timing: number | null;
  readonly scrollRateRange: string;
  readonly customEvent: string | null;
  readonly " $fragmentType": "PopupGroupPatternEditScreenContainer_popup";
};
export type PopupGroupPatternEditScreenContainer_popup = PopupGroupPatternEditScreenContainer_popup$data;
export type PopupGroupPatternEditScreenContainer_popup$key = {
  readonly " $data"?: PopupGroupPatternEditScreenContainer_popup$data;
  readonly " $fragmentSpreads": FragmentRefs<"PopupGroupPatternEditScreenContainer_popup">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkUrl",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInheritQuery",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "honeycombCode",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PopupGroupPatternEditScreenContainer_popup",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "page",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Funnel",
          "kind": "LinkedField",
          "name": "funnel",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupImage",
      "kind": "LinkedField",
      "name": "popupImages",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "alt",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "imageUrl",
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PopupVideo",
      "kind": "LinkedField",
      "name": "popupVideo",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "video",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "screenType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "colorKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "button",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hrefTarget",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentWidth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actionKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "xOffset",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "xOffsetUnit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yOffset",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yOffsetUnit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enableTapWindow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundOpacity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quitTiming",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayCountLimit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayPopupAgain",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayPopupAgainImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayPopupAgainMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayOnMouseOut",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayOnTabFocus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isClose",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "htmlContent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "animationKind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closePopupByClickingOutside",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closeButtonPosition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closeButtonType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hideByScrollPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hideByOnfocus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ydaTrigger",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDisplayWhenNoOperation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scrollRateRange",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customEvent",
      "storageKey": null
    }
  ],
  "type": "Popup",
  "abstractKey": null
};
})();

(node as any).hash = "1c238108ced9f6db2b47d9f516d97491";

export default node;
