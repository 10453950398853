import {
  Modal as ChakraModal,
  Image,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { FC } from "react";

import {
  PopupCloseButton,
  PopupCloseButtonPosition,
} from "~/src/__generated__/schema";
import { chakraFactory } from "~/src/lib/chakra-ui";

import closeIcon1 from "../assets/icon_close_1.png";
import closeIcon2 from "../assets/icon_close_2.png";
import closeIcon3 from "../assets/icon_close_3.png";

export type Props = {
  isOpen: boolean;
  width?: string;
  onClose: () => void;
  isShowDefaultIcon: boolean;
  closeButtonPosition: PopupCloseButtonPosition;
  closeButtonType: PopupCloseButton;
};

const StyledModalContent = chakraFactory(ModalContent, {
  baseStyle: {
    borderRadius: 0,
    backgroundColor: "transparent",
  },
});

const StyledCloseLink = chakraFactory("a", {
  baseStyle: {
    display: "inline-block",
    padding: 0,
    marginBottom: "4px",
    backgroundColor: "transparent",
  },
});

const StyledImage = chakraFactory(Image, {
  baseStyle: {
    display: "inline-block",
    maxWidth: "50px",
  },
});

const iconMapping = {
  CLOSE_ICON_DEFAULT: closeIcon1,
  CLOSE_ICON_JP: closeIcon2,
  CLOSE_ICON_EN: closeIcon3,
};

export const PopupPreviewModal: FC<Props> = ({
  children,
  isOpen,
  width = "450px",
  onClose,
  closeButtonPosition,
  closeButtonType,
  isShowDefaultIcon,
}) => {
  const closeIcon = isShowDefaultIcon
    ? iconMapping[closeButtonType as keyof typeof iconMapping]
    : closeIcon1;
  return isOpen ? (
    <ChakraModal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <StyledModalContent maxWidth={width}>
        {closeButtonPosition === "TOP_LEFT" && (
          <StyledCloseLink width={width} onClick={onClose} textAlign={"left"}>
            <StyledImage src={closeIcon} />
          </StyledCloseLink>
        )}
        {closeButtonPosition === "TOP_RIGHT" && (
          <StyledCloseLink width={width} onClick={onClose} textAlign={"right"}>
            <StyledImage src={closeIcon} />
          </StyledCloseLink>
        )}
        {children}
        {closeButtonPosition === "BOTTOM_LEFT" && (
          <StyledCloseLink width={width} onClick={onClose} textAlign={"left"}>
            <StyledImage src={closeIcon} />
          </StyledCloseLink>
        )}
        {closeButtonPosition === "BOTTOM_RIGHT" && (
          <StyledCloseLink width={width} onClick={onClose} textAlign={"right"}>
            <StyledImage src={closeIcon} />
          </StyledCloseLink>
        )}
      </StyledModalContent>
    </ChakraModal>
  ) : null;
};
