/**
 * @generated SignedSource<<406bc6a1127701e0f116536b3273c90a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type GroupContentJudgeStatus = "PENDING" | "APPROVED" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type UserRole = "SUPER" | "ADMIN_ALL" | "ADMIN" | "JUDGE" | "CLIENT" | "SAAS_CLIENT" | "%future added value";
export type FunnelABTestGroupList_Query$variables = {
  siteSlug: string;
  funnelIds?: ReadonlyArray<string> | null;
  pageIds?: ReadonlyArray<string> | null;
  count?: number | null;
  cursor?: string | null;
  sourceId?: string | null;
  device?: Device | null;
  withArchived?: boolean | null;
  status?: DistributionStatus | null;
};
export type FunnelABTestGroupList_QueryVariables = FunnelABTestGroupList_Query$variables;
export type FunnelABTestGroupList_Query$data = {
  readonly viewer: {
    readonly role: UserRole;
  };
  readonly site: {
    readonly abtestScenarioPageGroups: {
      readonly __id: string;
      readonly totalCount: number;
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly abtestScenarioPages: {
            readonly totalCount: number;
          } | null;
          readonly page: {
            readonly id: string;
            readonly kind: PageKind;
            readonly name: string;
            readonly url: string;
            readonly sortNumber: number;
          };
          readonly endDate: string | null;
          readonly id: string;
          readonly memo: string | null;
          readonly slug: string;
          readonly startDate: string | null;
          readonly status: DistributionStatus;
          readonly title: string;
          readonly updatedAt: string | null;
          readonly isArchive: boolean;
          readonly judgeStatus: GroupContentJudgeStatus | null;
        } | null;
      } | null> | null;
    };
  };
};
export type FunnelABTestGroupList_QueryResponse = FunnelABTestGroupList_Query$data;
export type FunnelABTestGroupList_Query = {
  variables: FunnelABTestGroupList_QueryVariables;
  response: FunnelABTestGroupList_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "device"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "funnelIds"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageIds"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteSlug"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withArchived"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v10 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "siteSlug"
  }
],
v11 = {
  "kind": "Variable",
  "name": "device",
  "variableName": "device"
},
v12 = {
  "kind": "Variable",
  "name": "funnelIds",
  "variableName": "funnelIds"
},
v13 = {
  "kind": "Variable",
  "name": "pageIds",
  "variableName": "pageIds"
},
v14 = {
  "kind": "Variable",
  "name": "sourceId",
  "variableName": "sourceId"
},
v15 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v16 = {
  "kind": "Variable",
  "name": "withArchived",
  "variableName": "withArchived"
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v19 = [
  (v17/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "AbtestScenarioPageGroupEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AbtestScenarioPageGroup",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "exceptKind",
                "value": "DEFAULT"
              }
            ],
            "concreteType": "AbtestScenarioPageConnection",
            "kind": "LinkedField",
            "name": "abtestScenarioPages",
            "plural": false,
            "selections": [
              (v17/*: any*/)
            ],
            "storageKey": "abtestScenarioPages(exceptKind:\"DEFAULT\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Page",
            "kind": "LinkedField",
            "name": "page",
            "plural": false,
            "selections": [
              (v18/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sortNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endDate",
            "storageKey": null
          },
          (v18/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "memo",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isArchive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "judgeStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__id",
        "storageKey": null
      }
    ]
  }
],
v20 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v11/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v12/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FunnelABTestGroupList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": "abtestScenarioPageGroups",
            "args": [
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/)
            ],
            "concreteType": "AbtestScenarioPageGroupConnection",
            "kind": "LinkedField",
            "name": "__FunnelABTestGroupList_abtestScenarioPageGroups_connection",
            "plural": false,
            "selections": (v19/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v8/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "FunnelABTestGroupList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v18/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "Site",
        "kind": "LinkedField",
        "name": "site",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v20/*: any*/),
            "concreteType": "AbtestScenarioPageGroupConnection",
            "kind": "LinkedField",
            "name": "abtestScenarioPageGroups",
            "plural": false,
            "selections": (v19/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v20/*: any*/),
            "filters": [
              "funnelIds",
              "pageIds",
              "sourceId",
              "device",
              "withArchived",
              "status"
            ],
            "handle": "connection",
            "key": "FunnelABTestGroupList_abtestScenarioPageGroups",
            "kind": "LinkedHandle",
            "name": "abtestScenarioPageGroups"
          },
          (v18/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b43c54afeacb25d134d457a85ebc4f3f",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "count",
          "cursor": "cursor",
          "direction": "forward",
          "path": [
            "site",
            "abtestScenarioPageGroups"
          ]
        }
      ]
    },
    "name": "FunnelABTestGroupList_Query",
    "operationKind": "query",
    "text": "query FunnelABTestGroupList_Query(\n  $siteSlug: String!\n  $funnelIds: [ID!]\n  $pageIds: [ID!]\n  $count: Int\n  $cursor: String\n  $sourceId: ID\n  $device: Device\n  $withArchived: Boolean\n  $status: DistributionStatus\n) {\n  viewer {\n    role\n    id\n  }\n  site(slug: $siteSlug) {\n    abtestScenarioPageGroups(funnelIds: $funnelIds, pageIds: $pageIds, first: $count, after: $cursor, sourceId: $sourceId, device: $device, withArchived: $withArchived, status: $status) {\n      totalCount\n      edges {\n        node {\n          abtestScenarioPages(exceptKind: DEFAULT) {\n            totalCount\n          }\n          page {\n            id\n            kind\n            name\n            url\n            sortNumber\n          }\n          endDate\n          id\n          memo\n          slug\n          startDate\n          status\n          title\n          updatedAt\n          isArchive\n          judgeStatus\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e4f50718e2125a77417584e1e1f03434";

export default node;
