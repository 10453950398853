import { useField } from "formik";
import { FC } from "react";

import {
  RadioImageButtons,
  RadioImageButtonsProps,
} from "~/src/components/features/popup/PopupGroupPatternForm/presentations/PopupRadioImageButtonsField/RadioImageButtons";

export type Props = {
  readonly name: string;
  readonly isDisabled?: boolean;
  readonly options: RadioImageButtonsProps["options"];
};

export const PopupRadioImageButtonsField: FC<Props> = ({
  name,
  isDisabled,
  options,
}) => {
  const [{ value }, , { setValue }] = useField<string>(name);

  return (
    <RadioImageButtons
      name={name}
      value={value}
      isDisabled={isDisabled}
      options={options}
      onChange={setValue}
    />
  );
};
