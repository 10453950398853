import { Box, Flex, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import { Form, Formik, FormikConfig } from "formik";
import { FC } from "react";

import { GraphQLEnums } from "~/src/__generated__/GraphQLEnums";
import { UserRole } from "~/src/__generated__/schema";
import { FormControl } from "~/src/components/common/forms/FormControl";
import { FormControlGroup } from "~/src/components/common/forms/FormControlGroup";
import { FormikCheckboxField } from "~/src/components/common/forms/FormikCheckboxField";
import { FormikFormButtons } from "~/src/components/common/forms/FormikFormButtons";
import { FormikRadioButtonsField } from "~/src/components/common/forms/FormikRadioButtonsField";
import { FormikSelectField } from "~/src/components/common/forms/FormikSelectField";
import { FormikSwitchField } from "~/src/components/common/forms/FormikSwitchField";
import { FormikTextareaField } from "~/src/components/common/forms/FormikTextareaField";
import { FormikTextField } from "~/src/components/common/forms/FormikTextField";
import { HorizontalSeparator } from "~/src/components/common/Separator";
import { PopupRadioImageButtonsField } from "~/src/components/features/popup/PopupGroupPatternForm/presentations/PopupRadioImageButtonsField";

import { PopupAgainImageForm } from "./presentations/PopupAgainImageForm";
import { PopupDisplayOptionFormContainer } from "./presentations/PopupDisplayOptionForm";
import { PopupHtmlFormContainer } from "./presentations/PopupHtmlForm";
import { PopupImageArrayFormContainer } from "./presentations/PopupImagesForm";
import { PopupVideoFormContainer } from "./presentations/PopupVideoForm";
import { FormValues, validationSchema } from "./validationSchema";

export type Props = {
  role: UserRole;
  initialValues: FormValues;
  enableWeightFields: boolean;
  onCancelClick: () => void;
  onSubmit: FormikConfig<FormValues>["onSubmit"];
  isDisableOriginalForm?: boolean;
};

const unitOptions = [
  { label: "px", value: "px" },
  { label: "%", value: "%" },
];

export const PopupGroupPatternForm: FC<Props> = ({
  role,
  initialValues,
  enableWeightFields,
  onCancelClick,
  onSubmit,
  isDisableOriginalForm,
}) => (
  <Formik<FormValues>
    enableReinitialize
    initialValues={initialValues}
    validationSchema={validationSchema}
    validateOnMount={false}
    validateOnChange={false}
    onSubmit={onSubmit}
  >
    {({ values }) => (
      <Form>
        <VStack spacing="24px" alignItems="flex-start">
          {enableWeightFields && !isDisableOriginalForm && (
            <FormControlGroup>
              <FormControl required label="パターン名">
                <Text fontSize={"13px"} fontWeight={400}>
                  デフォルト
                </Text>
              </FormControl>
              <FormControl required label="配信比率">
                <FormikTextField
                  type="number"
                  mode="fill"
                  name="originalPatternWeight"
                />
                <Text fontSize={"13px"} textColor={"#D9534F"}>
                  ※0以上の数値を入力してください（より大きな数が優先されます）
                  <br />
                  ※0は配信対象外です
                </Text>
              </FormControl>
            </FormControlGroup>
          )}

          <FormControlGroup>
            <FormControl required label="パターン名">
              <FormikTextField
                type="text"
                mode="fill"
                name="name"
                rightAdornment={`${values.name.length}/50`}
              />
            </FormControl>
            {enableWeightFields && (
              <FormControl required label="配信比率">
                <FormikTextField type="number" mode="fill" name="weight" />
                <Text fontSize={"13px"} textColor={"#D9534F"}>
                  ※0以上の数値を入力してください（より大きな数が優先されます）
                  <br />
                  ※0は配信対象外です
                </Text>
              </FormControl>
            )}

            <FormControl label="ポップアップの種類">
              <FormikRadioButtonsField
                name="contentKind"
                options={GraphQLEnums.PopupContentKind}
              />
            </FormControl>

            <HorizontalSeparator />

            {values.contentKind === "IMAGE" && (
              <PopupImageArrayFormContainer role={role} />
            )}
            {values.contentKind === "VIDEO" && (
              <PopupVideoFormContainer role={role} />
            )}
            {values.contentKind === "TEXT" && (
              <PopupDisplayOptionFormContainer values={values} />
            )}
            {values.contentKind === "HTML" && <PopupHtmlFormContainer />}
          </FormControlGroup>

          <FormControlGroup>
            <FormControl label="閉じるボタンの表示">
              <FormikSwitchField name="isClose" />
            </FormControl>
            {values.isClose && (
              <FormControl label="閉じるボタンの位置">
                <FormikSelectField
                  name="closeButtonPosition"
                  options={GraphQLEnums.PopupCloseButtonPosition}
                />
              </FormControl>
            )}
            {values.isClose && (
              <FormControl label={"閉じるボタン"}>
                <HStack justifyContent={"space-between"}>
                  <PopupRadioImageButtonsField
                    name="closeButtonType"
                    options={GraphQLEnums.PopupCloseButton}
                  />
                </HStack>
              </FormControl>
            )}
            <FormControl label="ポップアップの幅">
              <FormikTextField
                type="number"
                mode="fill"
                name="contentWidth"
                placeholder="200~450までの数字を入力"
                rightAdornment="px"
              />
            </FormControl>
          </FormControlGroup>

          {values.contentKind !== "TEXT" && (
            <FormControlGroup description="表示オプション">
              <PopupDisplayOptionFormContainer values={values} />
            </FormControlGroup>
          )}

          <FormControlGroup>
            <FormControl required label="表示タイミング">
              <FormikSelectField
                name="actionKind"
                options={GraphQLEnums.PopupActionKind}
              />
              <Flex alignItem="left">
                <Stack>
                  <FormikCheckboxField name="displayOnTabFocus">
                    タブの切り替えで表示
                  </FormikCheckboxField>
                  <FormikCheckboxField name="displayOnMouseOut">
                    ポインタが外れた際にポップアップを表示
                  </FormikCheckboxField>
                  <FormikCheckboxField name="isDisplayWhenNoOperation">
                    15秒ユーザー操作がない場合ポップアップを表示
                  </FormikCheckboxField>
                </Stack>
              </Flex>
              {[
                "DURATION",
                "SCROLL_RATE",
                "EXIT_AND_SCROLL_RATE_1",
                "EXIT_AND_SCROLL_RATE_2",
                "EXIT_AND_SCROLL_RATE_3",
                "SCROLL_PIXEL",
              ].includes(values.actionKind) && (
                <Box mt="16px">
                  <FormikTextField
                    type="number"
                    mode="fill"
                    name="timing"
                    placeholder="0  ※0以上の整数値"
                    rightAdornment={
                      values.actionKind === "DURATION"
                        ? "秒"
                        : [
                            "SCROLL_RATE",
                            "EXIT_AND_SCROLL_RATE_1",
                            "EXIT_AND_SCROLL_RATE_2",
                            "EXIT_AND_SCROLL_RATE_3",
                          ].includes(values.actionKind)
                        ? "%"
                        : values.actionKind === "SCROLL_PIXEL"
                        ? "px"
                        : undefined
                    }
                  />
                </Box>
              )}
              {values.actionKind === "SCROLL_RATE_RANGE" && (
                <Box mt="16px">
                  <FormikTextField
                    type="text"
                    mode="fill"
                    name="scrollRateRange"
                    placeholder="0~0  ※全て半角で範囲を指定してください"
                  />
                </Box>
              )}
              {values.actionKind === "CUSTOM_EVENT" && (
                <Box mt="16px">
                  <FormikTextareaField
                    mode="fill"
                    name="customEvent"
                    placeholder="js"
                  />
                </Box>
              )}
            </FormControl>
            <FormControl required label="表示場所">
              <FormikSelectField
                name="displayKind"
                options={GraphQLEnums.PopupDisplayKind}
              />
              {values.displayKind === "CUSTOM_MODAL" && (
                <VStack mt={"8px"}>
                  <FormControl label="画面左端から" dense>
                    <HStack>
                      <Box w={"100%"} minW={"100px"} maxW={"200px"}>
                        <FormikTextField
                          type="number"
                          mode="fill"
                          name="xOffset"
                        />
                      </Box>
                      <Box minW={"80px"}>
                        <FormikSelectField
                          name="xOffsetUnit"
                          options={unitOptions}
                        />
                      </Box>
                    </HStack>
                  </FormControl>
                  <FormControl label="画面上端から" dense>
                    <HStack>
                      <Box w={"100%"} minW={"100px"} maxW={"200px"}>
                        <FormikTextField
                          type="number"
                          mode="fill"
                          name="yOffset"
                        />
                      </Box>
                      <Box minW={"80px"}>
                        <FormikSelectField
                          name="yOffsetUnit"
                          options={unitOptions}
                        />
                      </Box>
                    </HStack>
                  </FormControl>
                </VStack>
              )}
            </FormControl>
            <FormControl required label="背景の操作">
              <FormikCheckboxField name="enableTapWindow">
                クリックさせる
              </FormikCheckboxField>
              {/* <FormikSwitchField name="enableTapWindow" /> */}
              {/* <FormikRadioButtonsField
                name="enableTapWindow"
                options={[
                  { label: "クリックさせる", value: true },
                  { label: "クリックさせない", value: false },
                ]}
              /> */}
            </FormControl>
            <FormControl
              required
              label="背景をクリック時にポップアップを非表示にする"
            >
              <FormikRadioButtonsField
                name="closePopupByClickingOutside"
                options={GraphQLEnums.PopupClosePopupByClickingOutside}
              />
            </FormControl>
            <FormControl label="背景濃度">
              <FormikTextField
                type="number"
                mode="fill"
                name="backgroundOpacity"
                placeholder="0.0~1までの数字を入力"
              />
            </FormControl>
            <FormControl label="スクロール率制限">
              <FormikTextField
                type="number"
                mode="fill"
                name="hideByScrollPercentage"
                placeholder="0~100までの数字を入力"
              />
            </FormControl>
            <FormControl label="入力中にポップアップを表示させない">
              <FormikCheckboxField name="hideByOnfocus" />
            </FormControl>
            <FormControl label="表示時間">
              <FormikTextField
                type="number"
                mode="fill"
                name="quitTiming"
                rightAdornment="秒"
              />
            </FormControl>
            <FormControl label="表示回数を制限">
              <FormikTextField
                type="number"
                mode="fill"
                name="displayCountLimit"
              />
            </FormControl>

            <FormControl label="再表示ボタン">
              <FormikRadioButtonsField
                name="displayPopupAgain"
                options={GraphQLEnums.DistributionStatus}
              />
            </FormControl>

            {values.displayPopupAgain === "ACTIVE" && (
              <PopupAgainImageForm
                imageUrl={values.displayPopupAgainImage.imageUrl}
              />
            )}

            {values.displayPopupAgain === "ACTIVE" && (
              <FormControl label="再表示ボタン用メッセージ">
                <FormikTextField
                  type="text"
                  mode="fill"
                  name="displayPopupAgainMessage"
                />
              </FormControl>
            )}

            <FormControl required label="アニメーション">
              <FormikSelectField
                name="animationKind"
                options={GraphQLEnums.PopupAnimationKind}
              />
            </FormControl>

            {(role === "ADMIN" ||
              role === "SUPER" ||
              role === "ADMIN_ALL" ||
              role === "CLIENT") && (
              <FormControl label="YDAコード">
                <FormikTextField
                  name="honeycombCode"
                  type="text"
                  mode="fill"
                  placeholder="iframeで読み込むURL"
                />
                <FormikRadioButtonsField
                  name="ydaTrigger"
                  options={GraphQLEnums.PopupYdaTrigger}
                />
              </FormControl>
            )}
          </FormControlGroup>

          <FormControlGroup>
            <FormControl label="ステータス">
              <FormikRadioButtonsField
                name="status"
                options={GraphQLEnums.DistributionStatus}
              />
            </FormControl>
          </FormControlGroup>

          <FormikFormButtons onCancelClick={onCancelClick} />
        </VStack>
      </Form>
    )}
  </Formik>
);
