/**
 * @generated SignedSource<<28b7569a5f997490ce54fcdd7134fb2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type PageKind = "LANDING" | "FORM" | "CONFIRM" | "THANKS" | "%future added value";
export type PatternContentJudgeStatus = "PENDING" | "APPROVED" | "REJECTED" | "%future added value";
export type CreatePopupSettingInput = {
  clientMutationId?: string | null;
  popupSetId: string;
  popupId: string;
};
export type PopupLinkFormContainer_Mutation$variables = {
  input: CreatePopupSettingInput;
  connections: ReadonlyArray<string>;
};
export type PopupLinkFormContainer_MutationVariables = PopupLinkFormContainer_Mutation$variables;
export type PopupLinkFormContainer_Mutation$data = {
  readonly createPopupSetting: {
    readonly popupSettingEdge: {
      readonly node: {
        readonly id: string;
        readonly popupSet: {
          readonly id: string;
          readonly name: string;
          readonly memo: string | null;
        };
        readonly popup: {
          readonly id: string;
          readonly slug: string;
          readonly name: string | null;
          readonly status: DistributionStatus;
          readonly judgeStatus: PatternContentJudgeStatus;
          readonly thumbnailImageUrl: string | null;
          readonly popupGroup: {
            readonly id: string;
            readonly slug: string;
            readonly name: string;
          };
          readonly page: {
            readonly id: string;
            readonly slug: string;
            readonly kind: PageKind;
            readonly name: string;
            readonly sortNumber: number;
            readonly url: string;
            readonly funnel: {
              readonly id: string;
              readonly slug: string;
              readonly name: string;
              readonly siteUrl: string;
            };
          };
          readonly " $fragmentSpreads": FragmentRefs<"PopupBulkEditFormContainer_popup">;
        };
      } | null;
    };
  } | null;
};
export type PopupLinkFormContainer_MutationResponse = PopupLinkFormContainer_Mutation$data;
export type PopupLinkFormContainer_Mutation = {
  variables: PopupLinkFormContainer_MutationVariables;
  response: PopupLinkFormContainer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "PopupSet",
  "kind": "LinkedField",
  "name": "popupSet",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "memo",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "judgeStatus",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnailImageUrl",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Page",
  "kind": "LinkedField",
  "name": "page",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sortNumber",
      "storageKey": null
    },
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Funnel",
      "kind": "LinkedField",
      "name": "funnel",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v6/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "siteUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weight",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkUrl",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isInheritQuery",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "honeycombCode",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupLinkFormContainer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreatePopupSettingPayload",
        "kind": "LinkedField",
        "name": "createPopupSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PopupSettingEdge",
            "kind": "LinkedField",
            "name": "popupSettingEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupSetting",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Popup",
                    "kind": "LinkedField",
                    "name": "popup",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v4/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PopupGroup",
                        "kind": "LinkedField",
                        "name": "popupGroup",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v6/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "PopupBulkEditFormContainer_popup"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PopupLinkFormContainer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreatePopupSettingPayload",
        "kind": "LinkedField",
        "name": "createPopupSetting",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PopupSettingEdge",
            "kind": "LinkedField",
            "name": "popupSettingEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PopupSetting",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Popup",
                    "kind": "LinkedField",
                    "name": "popup",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v6/*: any*/),
                      (v4/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PopupGroup",
                        "kind": "LinkedField",
                        "name": "popupGroup",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v6/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "deliverKind",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Popup",
                            "kind": "LinkedField",
                            "name": "originalPopup",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PopupImage",
                        "kind": "LinkedField",
                        "name": "popupImages",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "alt",
                            "storageKey": null
                          },
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "imageUrl",
                            "storageKey": null
                          },
                          (v14/*: any*/),
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PopupVideo",
                        "kind": "LinkedField",
                        "name": "popupVideo",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "video",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "screenType",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "contentKind",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "colorKind",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "button",
                        "storageKey": null
                      },
                      (v14/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hrefTarget",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "contentWidth",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "actionKind",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayKind",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "xOffset",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "xOffsetUnit",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "yOffset",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "yOffsetUnit",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "enableTapWindow",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "backgroundOpacity",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "quitTiming",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayCountLimit",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayPopupAgain",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayPopupAgainImageUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayPopupAgainMessage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayOnMouseOut",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayOnTabFocus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isClose",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "htmlContent",
                        "storageKey": null
                      },
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "animationKind",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "closePopupByClickingOutside",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "closeButtonPosition",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "closeButtonType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hideByScrollPercentage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hideByOnfocus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ydaTrigger",
                        "storageKey": null
                      },
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isDisplayWhenNoOperation",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "scrollRateRange",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customEvent",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "popupSettingEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c9eb44cc93a639a124a59f9432a4777d",
    "id": null,
    "metadata": {},
    "name": "PopupLinkFormContainer_Mutation",
    "operationKind": "mutation",
    "text": "mutation PopupLinkFormContainer_Mutation(\n  $input: CreatePopupSettingInput!\n) {\n  createPopupSetting(input: $input) {\n    popupSettingEdge {\n      node {\n        id\n        popupSet {\n          id\n          name\n          memo\n        }\n        popup {\n          id\n          slug\n          name\n          status\n          judgeStatus\n          thumbnailImageUrl\n          popupGroup {\n            id\n            slug\n            name\n          }\n          page {\n            id\n            slug\n            kind\n            name\n            sortNumber\n            url\n            funnel {\n              id\n              slug\n              name\n              siteUrl\n            }\n          }\n          ...PopupBulkEditFormContainer_popup\n        }\n      }\n    }\n  }\n}\n\nfragment PopupBulkEditFormContainer_popup on Popup {\n  id\n  slug\n  page {\n    id\n    funnel {\n      id\n    }\n  }\n  popupImages {\n    id\n    alt\n    linkUrl\n    imageUrl\n    isInheritQuery\n    honeycombCode\n  }\n  popupVideo {\n    linkUrl\n    video\n    screenType\n  }\n  name\n  contentKind\n  colorKind\n  title\n  description\n  button\n  isInheritQuery\n  url\n  hrefTarget\n  contentWidth\n  actionKind\n  displayKind\n  xOffset\n  xOffsetUnit\n  yOffset\n  yOffsetUnit\n  enableTapWindow\n  backgroundOpacity\n  quitTiming\n  displayCountLimit\n  status\n  displayPopupAgain\n  displayPopupAgainImageUrl\n  displayPopupAgainMessage\n  displayOnMouseOut\n  displayOnTabFocus\n  isClose\n  htmlContent\n  weight\n  animationKind\n  closePopupByClickingOutside\n  closeButtonPosition\n  closeButtonType\n  hideByScrollPercentage\n  hideByOnfocus\n  ydaTrigger\n  honeycombCode\n  isDisplayWhenNoOperation\n  popupGroup {\n    id\n    deliverKind\n    originalPopup {\n      weight\n      id\n    }\n  }\n  scrollRateRange\n  customEvent\n}\n"
  }
};
})();

(node as any).hash = "bf7b94034c91d7a4de4bdf1f590c6112";

export default node;
