/**
 * @generated SignedSource<<939b6277e06d9a16e4f42fc794c7a158>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Device = "MOBILE" | "TABLET" | "PC" | "%future added value";
export type DisplayPopupAgainStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type DistributionStatus = "ACTIVE" | "INACTIVE" | "%future added value";
export type PatternContentJudgeStatus = "PENDING" | "APPROVED" | "REJECTED" | "%future added value";
export type PopupActionKind = "DURATION" | "SCROLL_RATE" | "SCROLL_PIXEL" | "EXIT_PAGE" | "EXIT_AND_SCROLL_RATE_1" | "EXIT_AND_SCROLL_RATE_2" | "EXIT_AND_SCROLL_RATE_3" | "SCROLL_RATE_RANGE" | "CUSTOM_EVENT" | "%future added value";
export type PopupAnimationKind = "NO_ANIMATION" | "FROM_RIGHT" | "FROM_LEFT" | "FROM_TOP" | "FROM_BOTTOM" | "%future added value";
export type PopupCloseButton = "CLOSE_ICON_DEFAULT" | "CLOSE_ICON_JP" | "CLOSE_ICON_EN" | "%future added value";
export type PopupCloseButtonPosition = "TOP_RIGHT" | "TOP_LEFT" | "BOTTOM_LEFT" | "BOTTOM_RIGHT" | "%future added value";
export type PopupClosePopupByClickingOutside = "DISABLED" | "ENABLED" | "%future added value";
export type PopupColorKind = "DEFAULT" | "WHITE" | "GRAY" | "BLACK" | "RED" | "BLUE" | "GREEN" | "YELLOW" | "%future added value";
export type PopupContentKind = "IMAGE" | "VIDEO" | "TEXT" | "HTML" | "%future added value";
export type PopupDisplayKind = "CENTER_MODAL" | "LEFT_MODAL" | "RIGHT_MODAL" | "TOP_MODAL" | "BOTTOM_MODAL" | "LEFT_BOTTOM_MODAL" | "RIGHT_BOTTOM_MODAL" | "CUSTOM_MODAL" | "%future added value";
export type PopupHrefTarget = "BLANK" | "TOP" | "%future added value";
export type PopupKind = "A_PATTERN" | "B_PATTERN" | "%future added value";
export type PopupVideoScreenKind = "NORMAL" | "FULLSCREEN" | "%future added value";
export type PopupYdaTrigger = "YDA_AFFI" | "YDA_ALWAYS" | "YDA_SHOW" | "%future added value";
export type AddPopupInput = {
  clientMutationId?: string | null;
  actionKind: PopupActionKind;
  animationKind: PopupAnimationKind;
  backgroundOpacity: number;
  button?: string | null;
  buttonGradationColor?: string | null;
  buttonColor?: string | null;
  buttonTextColor?: string | null;
  contentKind?: PopupContentKind | null;
  customEvent?: string | null;
  closePopupByClickingOutside: PopupClosePopupByClickingOutside;
  closeButtonPosition?: PopupCloseButtonPosition | null;
  closeButtonType?: PopupCloseButton | null;
  colorKind?: PopupColorKind | null;
  contentWidth?: number | null;
  displayKind?: PopupDisplayKind | null;
  description?: string | null;
  displayOnTabFocus: boolean;
  displayOnMouseOut: boolean;
  enableTapWindow: boolean;
  hideByScrollPercentage: number;
  hideByOnfocus: boolean;
  htmlContent?: string | null;
  hrefTarget: PopupHrefTarget;
  honeycombCode?: string | null;
  isClose: boolean;
  isInheritQuery: boolean;
  name: string;
  popupGroupId: string;
  popupImages?: ReadonlyArray<PopupImageInput> | null;
  quitTiming?: number | null;
  scrollRateRange: string;
  status?: DistributionStatus | null;
  displayCountLimit?: number | null;
  displayPopupAgain?: DisplayPopupAgainStatus | null;
  displayPopupAgainImage?: File | Blog | null;
  displayPopupAgainMessage?: string | null;
  kind?: PopupKind | null;
  timing?: number | null;
  titleTextColor?: string | null;
  title?: string | null;
  url?: string | null;
  underlayerColor?: string | null;
  windowColor?: string | null;
  weight?: number | null;
  xOffset?: number | null;
  xOffsetUnit?: string | null;
  ydaTrigger?: PopupYdaTrigger | null;
  yOffset?: number | null;
  yOffsetUnit?: string | null;
  popupVideo?: PopupVideoInput | null;
  isDisplayWhenNoOperation: boolean;
};
export type PopupImageInput = {
  id?: string | null;
  alt?: string | null;
  linkUrl?: string | null;
  image?: File | Blog | null;
  isInheritQuery?: boolean | null;
  honeycombCode?: string | null;
};
export type PopupVideoInput = {
  video?: string | null;
  linkUrl?: string | null;
  screenType?: PopupVideoScreenKind | null;
  useOrgImage?: number | null;
  showControls?: number | null;
  showAnnotations?: number | null;
  stopMovieOnBlur?: number | null;
  optimizeDisplay?: number | null;
  mute?: number | null;
  autoPlay?: number | null;
  loop?: number | null;
};
export type UpdateOriginalPopupInput = {
  clientMutationId?: string | null;
  popupGroupId: string;
  status?: DistributionStatus | null;
  weight?: number | null;
};
export type ReportAnalyzerInput = {
  startOn: string;
  endOn: string;
  device?: Device | null;
  sourceId?: string | null;
  funnelId?: string | null;
};
export type PopupGroupPatternCreatePageContainer_Mutation$variables = {
  input: AddPopupInput;
  originalInput: UpdateOriginalPopupInput;
  withOriginal: boolean;
  analyzerInput: ReportAnalyzerInput;
  connections: ReadonlyArray<string>;
};
export type PopupGroupPatternCreatePageContainer_MutationVariables = PopupGroupPatternCreatePageContainer_Mutation$variables;
export type PopupGroupPatternCreatePageContainer_Mutation$data = {
  readonly updateOriginalPopup?: {
    readonly popup: {
      readonly id: string;
      readonly weight: number | null;
    };
  } | null;
  readonly addPopup: {
    readonly popupEdge: {
      readonly node: {
        readonly id: string;
        readonly slug: string;
        readonly name: string | null;
        readonly status: DistributionStatus;
        readonly isArchive: boolean;
        readonly kind: PopupKind | null;
        readonly judgeStatus: PatternContentJudgeStatus;
        readonly weight: number | null;
        readonly clickCvr: number;
        readonly clickCvUserCount: number;
        readonly clickUserCount: number;
        readonly clickUserRate: number;
        readonly cvr: number;
        readonly impressionCvUserCount: number;
        readonly impressionCvr: number;
        readonly impressionUserCount: number;
        readonly readyCvUserCount: number;
        readonly totalCvUserCount: number;
        readonly totalUserCount: number;
        readonly page: {
          readonly slug: string;
          readonly name: string;
          readonly funnel: {
            readonly name: string;
            readonly slug: string;
          };
        };
      } | null;
    };
  } | null;
};
export type PopupGroupPatternCreatePageContainer_MutationResponse = PopupGroupPatternCreatePageContainer_Mutation$data;
export type PopupGroupPatternCreatePageContainer_Mutation = {
  variables: PopupGroupPatternCreatePageContainer_MutationVariables;
  response: PopupGroupPatternCreatePageContainer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "analyzerInput"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "originalInput"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withOriginal"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weight",
  "storageKey": null
},
v7 = {
  "condition": "withOriginal",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "input",
          "variableName": "originalInput"
        }
      ],
      "concreteType": "UpdateOriginalPopupPayload",
      "kind": "LinkedField",
      "name": "updateOriginalPopup",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Popup",
          "kind": "LinkedField",
          "name": "popup",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            (v6/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v8 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isArchive",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "judgeStatus",
  "storageKey": null
},
v15 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "analyzerInput"
  }
],
v16 = {
  "alias": null,
  "args": (v15/*: any*/),
  "kind": "ScalarField",
  "name": "clickCvr",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": (v15/*: any*/),
  "kind": "ScalarField",
  "name": "clickCvUserCount",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": (v15/*: any*/),
  "kind": "ScalarField",
  "name": "clickUserCount",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": (v15/*: any*/),
  "kind": "ScalarField",
  "name": "clickUserRate",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": (v15/*: any*/),
  "kind": "ScalarField",
  "name": "cvr",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": (v15/*: any*/),
  "kind": "ScalarField",
  "name": "impressionCvUserCount",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": (v15/*: any*/),
  "kind": "ScalarField",
  "name": "impressionCvr",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": (v15/*: any*/),
  "kind": "ScalarField",
  "name": "impressionUserCount",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": (v15/*: any*/),
  "kind": "ScalarField",
  "name": "readyCvUserCount",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": (v15/*: any*/),
  "kind": "ScalarField",
  "name": "totalCvUserCount",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": (v15/*: any*/),
  "kind": "ScalarField",
  "name": "totalUserCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PopupGroupPatternCreatePageContainer_Mutation",
    "selections": [
      (v7/*: any*/),
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "AddPopupPayload",
        "kind": "LinkedField",
        "name": "addPopup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PopupEdge",
            "kind": "LinkedField",
            "name": "popupEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Popup",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v6/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Page",
                    "kind": "LinkedField",
                    "name": "page",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Funnel",
                        "kind": "LinkedField",
                        "name": "funnel",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "PopupGroupPatternCreatePageContainer_Mutation",
    "selections": [
      (v7/*: any*/),
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "AddPopupPayload",
        "kind": "LinkedField",
        "name": "addPopup",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PopupEdge",
            "kind": "LinkedField",
            "name": "popupEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Popup",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v6/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Page",
                    "kind": "LinkedField",
                    "name": "page",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Funnel",
                        "kind": "LinkedField",
                        "name": "funnel",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v9/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "appendEdge",
            "key": "",
            "kind": "LinkedHandle",
            "name": "popupEdge",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "209d93e76a46787bc01e63413f702f95",
    "id": null,
    "metadata": {},
    "name": "PopupGroupPatternCreatePageContainer_Mutation",
    "operationKind": "mutation",
    "text": "mutation PopupGroupPatternCreatePageContainer_Mutation(\n  $input: AddPopupInput!\n  $originalInput: UpdateOriginalPopupInput!\n  $withOriginal: Boolean!\n  $analyzerInput: ReportAnalyzerInput!\n) {\n  updateOriginalPopup(input: $originalInput) @include(if: $withOriginal) {\n    popup {\n      id\n      weight\n    }\n  }\n  addPopup(input: $input) {\n    popupEdge {\n      node {\n        id\n        slug\n        name\n        status\n        isArchive\n        kind\n        judgeStatus\n        weight\n        clickCvr(input: $analyzerInput)\n        clickCvUserCount(input: $analyzerInput)\n        clickUserCount(input: $analyzerInput)\n        clickUserRate(input: $analyzerInput)\n        cvr(input: $analyzerInput)\n        impressionCvUserCount(input: $analyzerInput)\n        impressionCvr(input: $analyzerInput)\n        impressionUserCount(input: $analyzerInput)\n        readyCvUserCount(input: $analyzerInput)\n        totalCvUserCount(input: $analyzerInput)\n        totalUserCount(input: $analyzerInput)\n        page {\n          slug\n          name\n          funnel {\n            name\n            slug\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "322c94d6ad032ceeab60fbafe9d7c09b";

export default node;
