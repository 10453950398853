import { FC, useMemo } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";

import { FunnelPopupGroupList_Query } from "~/src/__relay_artifacts__/FunnelPopupGroupList_Query.graphql";
import {
  Table,
  TableHeaderColumn,
  TableHeaderRow,
} from "~/src/components/common/tables/Table";

import { FormValues } from "./FunnelDetailsTableFilterButton";
import { FunnelPopupGroupTableDataRow } from "./FunnelPopupGroupTableDataRow";

const query = graphql`
  query FunnelPopupGroupList_Query(
    $siteSlug: String!
    $funnelIds: [ID!]
    $pageIds: [ID!]
    $count: Int
    $cursor: String
    $sourceId: ID
    $device: Device
    $withArchived: Boolean
    $status: DistributionStatus
  ) {
    viewer {
      role
    }
    site(slug: $siteSlug) {
      popupGroups(
        first: $count
        after: $cursor
        funnelIds: $funnelIds
        pageIds: $pageIds
        sourceId: $sourceId
        device: $device
        withArchived: $withArchived
        status: $status
      ) @connection(key: "FunnelPopupGroupList_popupGroups") {
        __id
        totalCount
        edges {
          node {
            endDate
            id
            memo
            name
            isArchive
            popups(kind: B_PATTERN) {
              totalCount
            }
            page {
              id
              kind
              name
              sortNumber
              url
            }
            slug
            status
            startDate
            thumbnailImageUrl
            updatedAt
            judgeStatus
          }
        }
      }
    }
  }
`;

type Props = {
  funnelId: string;
  pageId: string | null;
  siteSlug: string;
  readonly filterValues: FormValues;
};

export const FunnelPopupGroupList: FC<Props> = ({
  siteSlug,
  funnelId,
  pageId,
  filterValues,
}) => {
  const { site, viewer } = useLazyLoadQuery<FunnelPopupGroupList_Query>(query, {
    siteSlug,
    funnelIds: [funnelId],
    pageIds: pageId ? [pageId] : [],
    sourceId: filterValues.sourceId ? filterValues.sourceId : null,
    device: filterValues.device ? filterValues.device : null,
    withArchived: filterValues.withArchived ? true : null,
    status: filterValues.status ? filterValues.status : null,
  });

  const popupGroups = useMemo(() => {
    return (
      site.popupGroups.edges?.map((edge) => {
        const node = edge?.node;
        if (!node) throw new Error("assertion faild");
        return node;
      }) || []
    );
  }, [site.popupGroups]);

  return (
    <Table>
      <TableHeaderRow>
        <TableHeaderColumn>ポップアップ名</TableHeaderColumn>
        <TableHeaderColumn>実施ステータス</TableHeaderColumn>
        {viewer.role !== "SAAS_CLIENT" && (
          <TableHeaderColumn>承認状態</TableHeaderColumn>
        )}
        <TableHeaderColumn>画像</TableHeaderColumn>
        <TableHeaderColumn>実施ページ</TableHeaderColumn>
        <TableHeaderColumn>ポップアップパターン数</TableHeaderColumn>
        <TableHeaderColumn>開始日</TableHeaderColumn>
        <TableHeaderColumn>終了日</TableHeaderColumn>
        <TableHeaderColumn>最終更新日</TableHeaderColumn>
        <TableHeaderColumn>アクション</TableHeaderColumn>
      </TableHeaderRow>
      {popupGroups.map((popupGroup, index) => (
        <FunnelPopupGroupTableDataRow
          key={index}
          siteSlug={siteSlug}
          role={viewer.role}
          popupGroup={{
            id: popupGroup.id,
            name: popupGroup.name,
            slug: popupGroup.slug,
            status: popupGroup.status,
            judgeStatus: popupGroup.judgeStatus,
            imageUrl: popupGroup.thumbnailImageUrl,
            startDate: popupGroup.startDate,
            endDate: popupGroup.endDate,
            updatedAt: popupGroup.updatedAt,
            popupCount: popupGroup.popups ? popupGroup.popups.totalCount : 0,
            isArchive: popupGroup.isArchive,
          }}
          page={{
            id: popupGroup.page.id,
            kind: popupGroup.page.kind,
            name: popupGroup.page.name,
            url: popupGroup.page.url,
            sortNumber: popupGroup.page.sortNumber,
          }}
          funnelId={funnelId}
          connectionId={site.popupGroups.__id}
        />
      ))}
    </Table>
  );
};
